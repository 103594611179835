<template>
  <div>
    <b-overlay :show="showOverlay" rounded="sm">
      <b-card style="max-width: 100%; width: 100%" class="mb-2">
        <b-row>
          <b-col cols="1"> </b-col>
          <b-col cols="10">
            <h1>{{ $t("API.H1") }}</h1>
            <br />
            <br />
            <br />
            <h2>{{ $t("API.H2") }}</h2>
            <div class="h2-div">
              <br />
              <p>{{ $t("API.ABOUT") }}</p>
              <br />
              <h3>{{ $t("API.H3") }}</h3>
              <p>{{ $t("API.REST") }}</p>
              <p>
                {{ $t("API.READ_MORE") }}
                <a href="https://wikipedia.org/wiki/REST" target="_blank">{{
                  $t("API.HERE")
                }}</a
                >.
              </p>
            </div>
            <br />
            <h2>{{ $t("API.DATA") }}</h2>
            <div class="h2-div">
              <br />
              <h3>{{ $t("API.HOST") }}</h3>
              <b-card no-body bg-variant="light">
                <div class="h3-card-info">
                  <b class="card-text">https://api.natlogger.com</b>
                </div>
              </b-card>
              <br />
              <h3>{{ $t("API.TOKEN") }}*</h3>
              <b-card no-body bg-variant="light">
                <div class="h3-card-info">
                   <b
                    id="personal-token"
                    class="card-text"
                    >{{ token }}</b
                  >
                  <!-- <b
                    v-if="tokenIsAvailable"
                    id="personal-token"
                    class="card-text"
                    >{{ token }}</b
                  >
                  <b
                    v-else
                    id="token-not-available"
                    style="color: red"
                    class="card-text"
                    >{{ $t("API.TOKEN_NOT_AVAILABLE") }}</b
                  > -->
                </div>
              </b-card>
              <p>* {{ $t("API.TOKEN_DES") }}</p>
              <h3>{{ $t("API.TEAM") }}</h3>
              <b-card no-body bg-variant="light">
                <div class="h3-card-info">
                  <b class="card-text">{{ teamId }}</b>
                </div>
              </b-card>
            </div>
            <br />
            <br />
            <h2>{{ $t("API.DEFINES") }}</h2>
            <div class="h2-div">
              <b-card-header
                header-tag="header"
                class="p-1"
                style="border-bottom: 0"
                role="tab"
              >
                <b-button block v-b-toggle.accordion-01 variant="primary"
                  >{{ $t("API.SHOW") }}
                </b-button>
              </b-card-header>
              <b-collapse
                id="accordion-01"
                accordion="my-accordion01"
                role="tabpanel"
              >
                <div class="h3-card">
                  <h4>{{ $t("API.GROUP_TYPE") }}</h4>
                  <b-card no-body bg-variant="light">
                    <div class="h3-card-info">
                      <b class="card-text">NONE</b>
                      <span> - {{ $t("API.GROUP.NONE") }}</span>
                      <br />
                      <b class="card-text">SECOND</b>
                      <span> - {{ $t("API.GROUP.SECOND") }}</span>
                      <br />
                      <b class="card-text">MINUTE</b>
                      <span> - {{ $t("API.GROUP.MINUTE") }}</span>
                      <br />
                      <b class="card-text">HOUR</b>
                      <span> - {{ $t("API.GROUP.HOUR") }}</span>
                      <br />
                      <b class="card-text">DAY</b>
                      <span> - {{ $t("API.GROUP.DAY") }}</span>
                      <br />
                      <b class="card-text">WEEK</b>
                      <span> - {{ $t("API.GROUP.WEEK") }}</span>
                      <br />
                      <b class="card-text">MONTH</b>
                      <span> - {{ $t("API.GROUP.MONTH") }}</span>
                      <br />
                      <b class="card-text">YEAR</b>
                      <span> - {{ $t("API.GROUP.YEAR") }}</span>
                      <br />
                      <b class="card-text">AUTO</b>
                      <span> - {{ $t("API.GROUP.AUTO") }}</span>
                      <br />
                    </div>
                  </b-card>
                </div>
                <br />
              </b-collapse>
            </div>
            <br />
            <h2>{{ $t("API.METHODS") }}</h2>
            <div class="h2-div">
              <b-card-header
                header-tag="header"
                class="p-1"
                style="border-bottom: 0"
                role="tab"
              >
                <b-button block v-b-toggle.accordion-1 variant="primary"
                  >{{ $t("API.PARAMETERS") }}
                </b-button>
              </b-card-header>
              <b-collapse
                id="accordion-1"
                accordion="my-accordion1"
                role="tabpanel"
              >
                <h3>{{ $t("API.MAIN") }}</h3>
                <div class="h3-card">
                  <span>{{ $t("API.HTTP") }}</span>
                  <b-card no-body bg-variant="light">
                    <div class="h3-card-info">
                      <b class="card-text">GET</b>
                    </div>
                  </b-card>
                  <span>URL:</span>
                  <b-card no-body bg-variant="light">
                    <div class="h3-card-info">
                      <b class="card-text">/api/parameter</b>
                    </div>
                  </b-card>
                  <span>{{ $t("API.JSON") }}</span>
                </div>
                <br />
                <h3>{{ $t("API.REQUEST_PARAMS") }}</h3>
                <div class="h3-card">
                  <h4>{{ $t("API.REQUIRED") }}</h4>
                  <b-card no-body bg-variant="warning" class="required-block">
                    <div class="h3-card-info">
                      <b class="card-text">teamId</b>
                      <span> - {{ $t("API.TEAM") }}</span>
                      <br />
                    </div>
                  </b-card>
                  <h4>{{ $t("API.OPTIONAL") }}</h4>
                  <b-card no-body bg-variant="light">
                    <div class="h3-card-info">
                      <b class="card-text">unitId</b>
                      <span> - {{ $t("API.UNIT_ID") }}</span>
                      <br />
                      <b class="card-text">calculate</b>
                      <span> - {{ $t("API.CALCULATE") }}</span>
                      <br />
                      <b class="card-text">enrich</b>
                      <span> - {{ $t("API.ENRICH") }}</span>
                      <br />
                      <b class="card-text">count</b>
                      <span> - {{ $t("API.COUNT") }}</span>
                      <br />
                      <b class="card-text">start</b>
                      <span> - {{ $t("API.START") }}</span>
                      <br />
                      <b class="card-text">sortField</b>
                      <span> - {{ $t("API.SORT_FIELD") }}</span>
                      <br />
                      <b class="card-text">sortDir</b>
                      <span> - {{ $t("API.SORT_DIR") }}</span>
                      <br />
                    </div>
                  </b-card>
                  <b-button
                    v-b-toggle.collapse-1
                    class="m-1 mx-auto"
                    block
                    size="sm"
                    >{{ $t("API.EXAMPLE") }}
                  </b-button>
                  <b-collapse id="collapse-1">
                    <b-card no-body>
                      <br />
                      <h4 class="h3-card">{{ $t("API.REQUEST") }}</h4>
                      <b-card no-body bg-variant="light">
                        <div class="h3-card-info">
                          <span class="card-text">{{ paramRequest }}</span>
                          <br />
                        </div>
                      </b-card>
                      <br />
                      <h4 class="h3-card">{{ $t("API.RESPONSE") }}</h4>
                      <b-card no-body bg-variant="light">
                        <div class="h3-card-info">
                          <span
                            class="card-text"
                            style="white-space: pre-wrap"
                            v-html="paramResponse"
                          ></span>
                          <br />
                        </div>
                      </b-card>
                    </b-card>
                  </b-collapse>
                </div>
                <br />
              </b-collapse>
              <b-card-header
                header-tag="header"
                class="p-1"
                style="border-bottom: 0; border-top: 1px solid #ebedf3"
                role="tab"
              >
                <b-button block v-b-toggle.accordion-2 variant="primary"
                  >{{ $t("API.DEVICES") }}
                </b-button>
              </b-card-header>
              <b-collapse
                id="accordion-2"
                accordion="my-accordion2"
                role="tabpanel"
              >
                <h3>{{ $t("API.MAIN") }}</h3>
                <div class="h3-card">
                  <span>{{ $t("API.HTTP") }}:</span>
                  <b-card no-body bg-variant="light">
                    <div class="h3-card-info">
                      <b class="card-text">GET</b>
                    </div>
                  </b-card>
                  <span>URL:</span>
                  <b-card no-body bg-variant="light">
                    <div class="h3-card-info">
                      <b class="card-text">/api/device</b>
                    </div>
                  </b-card>
                  <span>{{ $t("API.JSON") }}</span>
                </div>
                <br />
                <h3>{{ $t("API.REQUEST_PARAMS") }}</h3>
                <div class="h3-card">
                  <h4>{{ $t("API.REQUIRED") }}</h4>
                  <b-card no-body bg-variant="warning" class="required-block">
                    <div class="h3-card-info">
                      <b class="card-text">teamId</b>
                      <span> - {{ $t("API.TEAM") }}</span>
                      <br />
                    </div>
                  </b-card>
                  <h4>{{ $t("API.OPTIONAL") }}</h4>
                  <b-card no-body bg-variant="light">
                    <div class="h3-card-info">
                      <b class="card-text">name</b>
                      <span> - {{ $t("API.NAME") }}</span>
                      <br />
                      <b class="card-text">deviceTypeId</b>
                      <span> - {{ $t("API.DEVICE_TYPE_IDS") }}</span>
                      <br />
                      <b class="card-text">withPublic</b>
                      <span> - {{ $t("API.PUBLIC") }}</span>
                      <br />
                      <b class="card-text">count</b>
                      <span> - {{ $t("API.COUNT") }}</span>
                      <br />
                      <b class="card-text">start</b>
                      <span> - {{ $t("API.START") }}</span>
                      <br />
                      <b class="card-text">sortField</b>
                      <span> - {{ $t("API.SORT_FIELD") }}</span>
                      <br />
                      <b class="card-text">sortDir</b>
                      <span> - {{ $t("API.SORT_DIR") }}</span>
                      <br />
                    </div>
                  </b-card>
                  <b-button
                    v-b-toggle.collapse-2
                    class="m-1 mx-auto"
                    block
                    size="sm"
                    >{{ $t("API.EXAMPLE") }}
                  </b-button>
                  <b-collapse id="collapse-2">
                    <b-card no-body>
                      <br />
                      <h4 class="h3-card">{{ $t("API.REQUEST") }}</h4>
                      <b-card no-body bg-variant="light">
                        <div class="h3-card-info">
                          <span class="card-text">{{ deviceRequest }}</span>
                          <br />
                        </div>
                      </b-card>
                      <br />
                      <h4 class="h3-card">{{ $t("API.RESPONSE") }}</h4>
                      <b-card no-body bg-variant="light">
                        <div class="h3-card-info">
                          <span
                            class="card-text"
                            style="white-space: pre-wrap"
                            v-html="deviceResponce"
                          ></span>
                          <br />
                        </div>
                      </b-card>
                    </b-card>
                  </b-collapse>
                </div>
                <br />
              </b-collapse>
              <b-card-header
                header-tag="header"
                class="p-1"
                style="border-bottom: 0; border-top: 1px solid #ebedf3"
                role="tab"
              >
                <b-button block v-b-toggle.accordion-3 variant="primary"
                  >{{ $t("API.MEASURE") }}
                </b-button>
              </b-card-header>
              <b-collapse
                id="accordion-3"
                accordion="my-accordion3"
                role="tabpanel"
              >
                <h3>{{ $t("API.MAIN") }}</h3>
                <div class="h3-card">
                  <span>{{ $t("API.HTTP") }}</span>
                  <b-card no-body bg-variant="light">
                    <div class="h3-card-info">
                      <b class="card-text">GET</b>
                    </div>
                  </b-card>
                  <span>URL:</span>
                  <b-card no-body bg-variant="light">
                    <div class="h3-card-info">
                      <b class="card-text">/api/measure</b>
                    </div>
                  </b-card>
                  <span>{{ $t("API.JSON") }}</span>
                </div>
                <br />
                <h3>{{ $t("API.REQUEST_PARAMS") }}</h3>
                <div class="h3-card">
                  <h4>{{ $t("API.REQUIRED") }}</h4>
                  <b-card no-body bg-variant="warning" class="required-block">
                    <div class="h3-card-info">
                      <b class="card-text">teamId</b>
                      <span> - {{ $t("API.TEAM") }}</span>
                      <br />
                    </div>
                  </b-card>
                  <h4>{{ $t("API.OPTIONAL") }}</h4>
                  <b-card no-body bg-variant="light">
                    <div class="h3-card-info">
                      <b class="card-text">deviceIds[]</b>
                      <span> - {{ $t("API.DEVICE_IDS") }}</span>
                      <br />
                      <b class="card-text">dataPackageId</b>
                      <span> - {{ $t("API.DATA_PACKAGE_ID") }}</span>
                      <br />
                      <b class="card-text">parameterIds[]</b>
                      <span> - {{ $t("API.PARAM_IDS") }}</span>
                      <br />
                      <b class="card-text">latitude</b>
                      <span> - {{ $t("API.LATITUDE") }}</span>
                      <br />
                      <b class="card-text">longitude</b>
                      <span> - {{ $t("API.LONGITUDE") }}</span>
                      <br />
                      <b class="card-text">radius</b>
                      <span> - {{ $t("API.RADIUS") }}</span>
                      <br />
                      <b class="card-text">startDate</b>
                      <span> - {{ $t("API.START_DATE") }}</span>
                      <br />
                      <b class="card-text">endDate</b>
                      <span> - {{ $t("API.END_DATE") }}</span>
                      <br />
                      <b class="card-text">groupType</b>
                      <span> - {{ $t("API.GROUP_TYPE") }}</span>
                      <br />
                      <b class="card-text">round</b>
                      <span> - {{ $t("API.ROUND") }}</span>
                      <br />
                      <b class="card-text">count</b>
                      <span> - {{ $t("API.COUNT") }}</span>
                      <br />
                      <b class="card-text">start</b>
                      <span> - {{ $t("API.START") }}</span>
                      <br />
                    </div>
                  </b-card>
                  <b-button
                    v-b-toggle.collapse-3
                    class="m-1 mx-auto"
                    block
                    size="sm"
                    >{{ $t("API.EXAMPLE") }}
                  </b-button>
                  <b-collapse id="collapse-3">
                    <b-card no-body>
                      <br />
                      <h4 class="h3-card">{{ $t("API.REQUEST") }}</h4>
                      <b-card no-body bg-variant="light">
                        <div class="h3-card-info">
                          <span class="card-text">{{ measureRequest }}</span>
                          <br />
                        </div>
                      </b-card>
                      <br />
                      <h4 class="h3-card">{{ $t("API.RESPONSE") }}</h4>
                      <b-card no-body bg-variant="light">
                        <div class="h3-card-info">
                          <span
                            class="card-text"
                            style="white-space: pre-wrap"
                            v-html="measureResponse"
                          ></span>
                          <br />
                        </div>
                      </b-card>
                    </b-card>
                  </b-collapse>
                </div>
                <br />
              </b-collapse>
              <b-card-header
                header-tag="header"
                class="p-1"
                style="border-bottom: 0; border-top: 1px solid #ebedf3"
                role="tab"
              >
                <b-button block v-b-toggle.accordion-4 variant="primary"
                  >{{ $t("API.CIRCLE") }}
                </b-button>
              </b-card-header>
              <b-collapse
                id="accordion-4"
                accordion="my-accordion4"
                role="tabpanel"
              >
                <h3>{{ $t("API.MAIN") }}</h3>
                <div class="h3-card">
                  <span>{{ $t("API.HTTP") }}</span>
                  <b-card no-body bg-variant="light">
                    <div class="h3-card-info">
                      <b class="card-text">GET</b>
                    </div>
                  </b-card>
                  <span>URL:</span>
                  <b-card no-body bg-variant="light">
                    <div class="h3-card-info">
                      <b class="card-text">/api/measure/circle</b>
                    </div>
                  </b-card>
                  <span>{{ $t("API.JSON") }}</span>
                </div>
                <br />
                <h3>{{ $t("API.REQUEST_PARAMS") }}</h3>
                <div class="h3-card">
                  <h4>{{ $t("API.REQUIRED") }}</h4>
                  <b-card no-body bg-variant="warning" class="required-block">
                    <div class="h3-card-info">
                      <b class="card-text">teamId</b>
                      <span> - {{ $t("API.TEAM") }}</span>
                      <br />
                    </div>
                  </b-card>
                  <h4>{{ $t("API.OPTIONAL") }}</h4>
                  <b-card no-body bg-variant="light">
                    <div class="h3-card-info">
                      <b class="card-text">deviceIds[]</b>
                      <span> - {{ $t("API.DEVICE_IDS") }}</span>
                      <br />
                      <b class="card-text">dataPackageId</b>
                      <span> - {{ $t("API.DATA_PACKAGE_ID") }}</span>
                      <br />
                      <b class="card-text">parameterIds</b>
                      <span> - {{ $t("API.PARAM_IDS") }}</span>
                      <br />
                      <b class="card-text">latitude</b>
                      <span> - {{ $t("API.LATITUDE") }}</span>
                      <br />
                      <b class="card-text">longitude</b>
                      <span> - {{ $t("API.LONGITUDE") }}</span>
                      <br />
                      <b class="card-text">radius</b>
                      <span> - {{ $t("API.RADIUS") }}</span>
                      <br />
                      <b class="card-text">startDate</b>
                      <span> - {{ $t("API.START_DATE") }}</span>
                      <br />
                      <b class="card-text">endDate</b>
                      <span> - {{ $t("API.END_DATE") }}</span>
                      <br />
                      <b class="card-text">round</b>
                      <span> - {{ $t("API.ROUND") }}</span>
                      <br />
                      <b class="card-text">count</b>
                      <span> - {{ $t("API.COUNT") }}</span>
                      <br />
                      <b class="card-text">start</b>
                      <span> - {{ $t("API.START") }}</span>
                      <br />
                    </div>
                  </b-card>
                  <b-button
                    v-b-toggle.collapse-4
                    class="m-1 mx-auto"
                    block
                    size="sm"
                    >{{ $t("API.EXAMPLE") }}
                  </b-button>
                  <b-collapse id="collapse-4">
                    <b-card no-body>
                      <br />
                      <h4 class="h3-card">{{ $t("API.REQUEST") }}</h4>
                      <b-card no-body bg-variant="light">
                        <div class="h3-card-info">
                          <span class="card-text">{{ circleRequest }}</span>
                          <br />
                        </div>
                      </b-card>
                      <br />
                      <h4 class="h3-card">{{ $t("API.RESPONSE") }}</h4>
                      <b-card no-body bg-variant="light">
                        <div class="h3-card-info">
                          <span
                            class="card-text"
                            style="white-space: pre-wrap"
                            v-html="circleResponse"
                          ></span>
                          <br />
                        </div>
                      </b-card>
                    </b-card>
                  </b-collapse>
                </div>
                <br />
              </b-collapse>
              <b-card-header
                header-tag="header"
                class="p-1"
                style="border-bottom: 0; border-top: 1px solid #ebedf3"
                role="tab"
              >
                <b-button block v-b-toggle.accordion-5 variant="primary"
                  >{{ $t("API.CSV") }}
                </b-button>
              </b-card-header>
              <b-collapse
                id="accordion-5"
                accordion="my-accordion5"
                role="tabpanel"
              >
                <h3>{{ $t("API.MAIN") }}</h3>
                <div class="h3-card">
                  <span>{{ $t("API.HTTP") }}</span>
                  <b-card no-body bg-variant="light">
                    <div class="h3-card-info">
                      <b class="card-text">GET</b>
                    </div>
                  </b-card>
                  <span>URL:</span>
                  <b-card no-body bg-variant="light">
                    <div class="h3-card-info">
                      <b class="card-text">/api/measure/csv</b>
                    </div>
                  </b-card>
                  <span>{{ $t("API.CSV_FILE") }}</span>
                </div>
                <br />
                <h3>{{ $t("API.REQUEST_PARAMS") }}</h3>
                <div class="h3-card">
                  <h4>{{ $t("API.REQUIRED") }}</h4>
                  <b-card no-body bg-variant="warning" class="required-block">
                    <div class="h3-card-info">
                      <b class="card-text">teamId</b>
                      <span> - {{ $t("API.TEAM") }}</span>
                      <br />
                    </div>
                  </b-card>
                  <h4>{{ $t("API.OPTIONAL") }}</h4>
                  <b-card no-body bg-variant="light">
                    <div class="h3-card-info">
                      <b class="card-text">deviceIds</b>
                      <span> - {{ $t("API.DEVICE_IDS") }}</span>
                      <br />
                      <b class="card-text">parameterIds</b>
                      <span> - {{ $t("API.PARAM_IDS") }}</span>
                      <br />
                      <b class="card-text">latitude</b>
                      <span> - {{ $t("API.LATITUDE") }}</span>
                      <br />
                      <b class="card-text">longitude</b>
                      <span> - {{ $t("API.LONGITUDE") }}</span>
                      <br />
                      <b class="card-text">radius</b>
                      <span> - {{ $t("API.RADIUS") }}</span>
                      <br />
                      <b class="card-text">startDate</b>
                      <span> - {{ $t("API.START_DATE") }}</span>
                      <br />
                      <b class="card-text">endDate</b>
                      <span> - {{ $t("API.END_DATE") }}</span>
                      <br />
                      <b class="card-text">groupType</b>
                      <span> - {{ $t("API.GROUP_TYPE") }}</span>
                      <br />
                      <b class="card-text">round</b>
                      <span> - {{ $t("API.ROUND") }}</span>
                      <br />
                      <b class="card-text">count</b>
                      <span> - {{ $t("API.COUNT") }}</span>
                      <br />
                      <b class="card-text">start</b>
                      <span> - {{ $t("API.START") }}</span>
                      <br />
                    </div>
                  </b-card>
                  <b-button
                    v-b-toggle.collapse-5
                    class="m-1 mx-auto"
                    block
                    size="sm"
                    >{{ $t("API.EXAMPLE") }}
                  </b-button>
                  <b-collapse id="collapse-5">
                    <b-card no-body>
                      <br />
                      <h4 class="h3-card">{{ $t("API.REQUEST") }}</h4>
                      <b-card no-body bg-variant="light">
                        <div class="h3-card-info">
                          <span class="card-text">{{ csvRequest }}</span>
                          <br />
                        </div>
                      </b-card>
                      <br />
                      <h4 class="h3-card">{{ $t("API.RESPONSE") }}</h4>
                      <b-card no-body bg-variant="light">
                        <div class="h3-card-info">
                          <span
                            class="card-text"
                            style="white-space: pre-wrap"
                            v-html="csvResponse"
                          ></span>
                          <br />
                        </div>
                      </b-card>
                    </b-card>
                  </b-collapse>
                </div>
                <br />
              </b-collapse>
            </div>
          </b-col>
          <b-col cols="1"> </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { API_REQUEST } from "../../core/services/store/api.module";
import { SET_BREADCRUMB } from "../../core/services/store/breadcrumbs.module";

export default {
  name: "Api",
  data() {
    return {
      token: localStorage.getItem("id_token"),
      teamId: ApiService.teamData.value,
      groupTypes: "NONE, SECOND, MINUTE, HOUR, DAY, WEEK, MONTH, YEAR, AUTO",
      paramRequest:
        "https://api.natlogger.com/api/parameter?teamId=" +
        ApiService.teamData.value +
        "&enrich=true&count=4&calculate=false&start=0",
      paramResponse:
        '"pages": null,<br>' +
        '"page": null,<br>' +
        '"totalCount": null,<br>' +
        '"queriedCount": 4,<br>' +
        '"startRecord": 0,<br>' +
        '"data": [{<br>' +
        '    "id": "da9a5df6-1814-4d4d-aef1-33ea7618175b",<br>' +
        '    "created": "2020-06-23T15:41:28.073Z",<br>' +
        '    "changed": "2020-08-05T07:09:57.817Z",<br>' +
        '    "code": "PR",<br>' +
        '    "name": "Preassure",<br>' +
        '    "global": true,<br>' +
        '    "description": "Pressure ",<br>' +
        '    "unitId": "1f6fc108-1978-485b-9b27-6fc440b3e085",<br>' +
        '    "unitName": null,<br>' +
        '    "deleted": false,<br>' +
        '    "teamId": "' +
        ApiService.teamData.value +
        '",<br>' +
        '    "teamName": null,<br>' +
        '    "attachment": false,<br>' +
        '    "circle": false<br>' +
        "}, {<br>" +
        '    "id": "e331c388-1fca-40a2-8654-0aaf01e89d83",<br>' +
        '    "created": "2020-06-23T15:36:27.515Z",<br>' +
        '    "changed": "2020-06-23T15:36:43.408Z",<br>' +
        '    "code": "HM",<br>' +
        '    "name": "Humidity",<br>' +
        '    "global": true,<br>' +
        '    "description": "Humidity",<br>' +
        '    "unitId": "1f6fc108-1978-485b-9b27-6fc440b3e086",<br>' +
        '    "unitName": null,<br>' +
        '    "deleted": false,<br>' +
        '    "teamId": "' +
        ApiService.teamData.value +
        '",<br>' +
        '    "teamName": null,<br>' +
        '    "attachment": false,<br>' +
        '    "circle": false<br>' +
        "}, {<br>" +
        '    "id": "e332c388-1fca-40a2-8654-0aaf01e89d83",<br>' +
        '    "created": "2020-06-23T15:36:27.515Z",<br>' +
        '    "changed": "2020-06-23T15:36:43.408Z",<br>' +
        '    "code": "WD",<br>' +
        '    "name": "Wind direction",<br>' +
        '    "global": true,<br>' +
        '    "description": "Wind direction",<br>' +
        '    "unitId": "1f6fc108-1978-485b-9b27-6fc440b3e087",<br>' +
        '    "unitName": null,<br>' +
        '    "deleted": false,<br>' +
        '    "teamId": "' +
        ApiService.teamData.value +
        '",<br>' +
        '    "teamName": null,<br>' +
        '    "attachment": false,<br>' +
        '    "circle": true<br>' +
        "}, {<br>" +
        '    "id": "ce20f462-cb30-4d31-bd94-54c983680270",<br>' +
        '    "created": "2020-06-23T11:12:11.832Z",<br>' +
        '    "changed": "2020-06-23T15:35:32.800Z",<br>' +
        '    "code": "t",<br>' +
        '    "name": "Temperature",<br>' +
        '    "global": true,<br>' +
        '    "description": "Temperature",<br>' +
        '    "unitId": "1f6fc108-1978-485b-9b27-6fc440b3e084",<br>' +
        '    "unitName": null,<br>' +
        '    "deleted": false,<br>' +
        '    "teamId": "' +
        ApiService.teamData.value +
        '",<br>' +
        '    "teamName": null,<br>' +
        '    "attachment": false,<br>' +
        '    "circle": false<br>' +
        "}],<br>" +
        '"recordsTotal": null,<br>' +
        '"recordsFiltered": null',
      deviceRequest:
        "https://api.natlogger.com/api/device?enrich=true&teamId=" +
        ApiService.teamData.value +
        "&count=1&calculate=false&start=0",
      deviceResponce:
        '"pages": null,<br>' +
        '"page": null,<br>' +
        '"totalCount": null,<br>' +
        '"queriedCount": 1,<br>' +
        '"startRecord": 0,<br>' +
        '"data": [{<br>' +
        '    "id": "a20ce502-bfb1-4f19-8f4b-7f0037a0a7d0",<br>' +
        '    "created": "2020-08-05T21:44:35.113Z",<br>' +
        '    "changed": "2020-09-04T10:08:58.175Z",<br>' +
        '    "name": "Вернадского с СоколМетео",<br>' +
        '    "typeId": "b02eae33-6d98-41ec-a9d2-63905277a357",<br>' +
        '    "typeName": "Sokol-M Model 101M",<br>' +
        '    "teamId": "6afc993f-35bc-4bd8-98a5-fbf1fc75bee4",<br>' +
        '    "teamName": "ООО Плюс",<br>' +
        '    "lat": 54.422317761431756,<br>' +
        '    "lng": 47.667968749999986,<br>' +
        '    "imei": "88002000600",<br>' +
        '    "password": "88002000600",<br>' +
        '    "active": true,<br>' +
        '    "publicAvailable": false,<br>' +
        '    "altitude": 0,<br>' +
        '    "timezoneId": null,<br>' +
        '    "timezoneOffset": null,<br>' +
        '    "locationManual": true,<br>' +
        '    "online": false,<br>' +
        '    "deviceData": {<br>' +
        '        "date": null,<br>' +
        '        "parameters": [{<br>' +
        '            "name": "Temperature",<br>' +
        '            "value": "33.08",<br>' +
        '            "unit": "c"<br>' +
        "            },<br>" +
        "            {<br>" +
        '            "name": "Preassure",<br>' +
        '            "value": "983.0",<br>' +
        '            "unit": "bar"<br>' +
        "            },<br>" +
        "            {<br>" +
        '            "name": "Wind direction",<br>' +
        '            "value": "203",<br>' +
        '            "unit": "rad"<br>' +
        "        }],<br>" +
        "    },<br>" +
        '    "phoneNumber": "",<br>' +
        '    "deviceTypeDescription": null,<br>' +
        '    "parameterMappings": [],<br>' +
        '    "parameterMappingsToDelete": null,<br>' +
        '    "timezoneLastUpdateDt": null,<br>' +
        '    "locationLastUpdateDt": null,<br>' +
        '    "lastDataReceivedDt": null,<br>' +
        '    "notConnectedNotificationDt": null<br>' +
        "}],<br>" +
        '"recordsTotal": null,<br>' +
        '"recordsFiltered": null',
      measureRequest:
        "https://api.natlogger.com/api/measure?teamId=" +
        ApiService.teamData.value +
        "&deviceIds[]=a20ce502-bfb1-4f19-8f4b-7f0037a0a7d0&parameterIds[]=ce20f462-cb30-4d31-bd94-54c983680270,e332c388-1fca-40a2-8654-0aaf01e89d83&startDate=2020-06-30T21:00:00.000Z&endDate=2020-08-30T21:00:00.000Z&realtime=false&groupType=AUTO",
      measureResponse:
        "[{<br>" +
        '  "date": "2020-06-30T21:00:00.000Z",<br>' +
        '  "measures": {<br>' +
        '    "a20ce502-bfb1-4f19-8f4b-7f0037a0a7d0": {<br>' +
        '      "latitude": 0.0,<br>' +
        '      "longitude": 0.0,<br>' +
        '      "altitude": 0,<br>' +
        '      "parameterValues": {<br>' +
        '        "e332c388-1fca-40a2-8654-0aaf01e89d83": 162.0,<br>' +
        '        "ce20f462-cb30-4d31-bd94-54c983680270": 24.2333<br>' +
        "      }<br>" +
        "    }<br>" +
        "  }<br>" +
        "}, {<br>" +
        '  "date": "2020-06-30T22:00:00.000Z",<br>' +
        '  "measures": {<br>' +
        '    "a20ce502-bfb1-4f19-8f4b-7f0037a0a7d0": {<br>' +
        '      "latitude": 0.0,<br>' +
        '      "longitude": 0.0,<br>' +
        '      "altitude": 0,<br>' +
        '      "parameterValues": {<br>' +
        '        "e332c388-1fca-40a2-8654-0aaf01e89d83": 162.0,<br>' +
        '        "ce20f462-cb30-4d31-bd94-54c983680270": 24.145<br>' +
        "      }<br>" +
        "    }<br>" +
        "  }<br>" +
        "}, {<br>" +
        '  "date": "2020-06-30T23:00:00.000Z",<br>' +
        '  "measures": {<br>' +
        '    "a20ce502-bfb1-4f19-8f4b-7f0037a0a7d0": {<br>' +
        '      "latitude": 0.0,<br>' +
        '      "longitude": 0.0,<br>' +
        '      "altitude": 0,<br>' +
        '      "parameterValues": {<br>' +
        '        "e332c388-1fca-40a2-8654-0aaf01e89d83": 162.0,<br>' +
        '        "ce20f462-cb30-4d31-bd94-54c983680270": 24.0567<br>' +
        "      }<br>" +
        "    }<br>" +
        "  }<br>" +
        "}]",
      circleRequest:
        "https://api.natlogger.com/api/measure/circle?teamId=" +
        ApiService.teamData.value +
        "&deviceIds[]=a20ce502-bfb1-4f19-8f4b-7f0037a0a7d0&parameter=e332c388-1fca-40a2-8654-0aaf01e89d83&startDate=2020-06-30T21:00:00.000Z&endDate=2020-08-30T21:00:00.000Z&realtime=false&parameterId=e332c388-1fca-40a2-8654-0aaf01e89d83",
      circleResponse:
        '"values": {<br>' +
        '  "3.0": 6,<br>' +
        '  "7.0": 12,<br>' +
        '  "13.0": 5,<br>' +
        '  "14.0": 4,<br>' +
        '  "16.0": 5,<br>' +
        '  "18.0": 5,<br>' +
        '  "21.0": 1,<br>' +
        '  "23.0": 3,<br>' +
        '  "24.0": 2,<br>' +
        '  "27.0": 8,<br>' +
        '  "31.0": 2,<br>' +
        '  "33.0": 2,<br>' +
        '  "37.0": 2,<br>' +
        '  "44.0": 1,<br>' +
        '  "47.0": 1,<br>' +
        '  "235.0": 6,<br>' +
        '  "244.0": 6,<br>' +
        '  "242.0": 2,<br>' +
        '  "247.0": 6,<br>' +
        '  "252.0": 8,<br>' +
        '  "254.0": 23,<br>' +
        '  "248.0": 1,<br>' +
        '  "255.0": 8,<br>' +
        '  "253.0": 11,<br>' +
        '  "251.0": 4,<br>' +
        '  "261.0": 1,<br>' +
        '  "271.0": 4,<br>' +
        '  "270.0": 2,<br>' +
        '  "278.0": 1,<br>' +
        '  "282.0": 5,<br>' +
        '  "277.0": 1,<br>' +
        '  "286.0": 2,<br>' +
        '  "275.0": 1,<br>' +
        '  "285.0": 2,<br>' +
        '  "296.0": 2,<br>' +
        '  "290.0": 5,<br>' +
        '  "305.0": 4,<br>' +
        '  "308.0": 7,<br>' +
        '  "312.0": 7,<br>' +
        '  "314.0": 5,<br>' +
        '  "318.0": 7,<br>' +
        '  "323.0": 8,<br>' +
        '  "322.0": 19,<br>' +
        '  "330.0": 9,<br>' +
        '  "320.0": 5,<br>' +
        '  "333.0": 4,<br>' +
        '  "335.0": 16,<br>' +
        '  "348.0": 19,<br>' +
        '  "338.0": 12,<br>' +
        '  "340.0": 15,<br>' +
        '  "341.0": 15,<br>' +
        '  "352.0": 24,<br>' +
        '  "354.0": 16,<br>' +
        '  "1.0": 9<br>' +
        "}",
      csvRequest:
        "https://api.natlogger.com/api/measure/csv?teamId=" +
        ApiService.teamData.value +
        "&parameterIds=e331c388-1fca-40a2-8654-0aaf01e89d83,ce20f462-cb30-4d31-bd94-54c983680270,e332c388-1fca-40a2-8654-0aaf01e89d83,da9a5df6-1814-4d4d-aef1-33ea7618175b&" +
        "latitude=55.849048333333&longitude=49.0948316666667&radius=300&startDate=2018-08-01T01:30:00.000-00:00&endDate=2020-10-31T01:30:00.000-00:00&groupType=HOUR",
      csvResponse: "[text file data]",
      tokenIsAvailable: false,
      showOverlay: true,
    };
  },
  async created() {
    await this.$store
      .dispatch(API_REQUEST, {
        resource: "/api/team/" + ApiService.teamData.value,
        requestType: "GET",
      })
      .then((response) => {
        let tariffId = response.tariffId;
        this.$store
          .dispatch(API_REQUEST, {
            resource:
              "/api/team/" + ApiService.teamData.value + "/available-tariffs",
            requestType: "GET",
          })
          .then((response) => {
            let index = response.findIndex((r) => r.id === tariffId);
            if (index < 0) {
              this.tokenIsAvailable = true;
            } else if (
              response[index].maxRequestCount == null ||
              response[index].maxRequestCount > 0
            ) {
              console.log(response[index].maxRequestCount);
              this.tokenIsAvailable = true;
            }
          })
          .catch(this.onError);
      })
      .catch(this.onError);
    this.$nextTick(function () {
      this.showOverlay = false;
    });
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("MENU.API") }]);
  },
  methods: {
    getToken() {
      return localStorage.getItem("token_id");
    },
  },
};
</script>

<style scoped>
.h2-div {
  margin-left: 2%;
  margin-right: 2%;
}

.h3-card {
  margin-left: 2%;
  margin-right: 2%;
}

.h3-card-info {
  margin-left: 2%;
  margin-right: 2%;
}

.required-block {
  margin-bottom: 8px;
}
</style>
